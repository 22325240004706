.cookieConcentWrapper {
  background: #fff;
  position: fixed;
  width: 50vw;
  height: 80vh;
  z-index: 10000000;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  transform: translate(-50%, -50%);
  padding: 30px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.75);
}
.cookieConcentWrapper img {
  width: 180px;
}
.cookieConcentWrapper p {
  text-align: center;
  font-size: 20px;
}
.cookieConcentWrapper h3 {
  font-size: 38px;
}
.cookieConcentWrapper button {
  background-color: #e2894c;
  color: #2e1708;
  text-transform: uppercase;
  border: 1px solid #2e1708;
  padding: 5px 20px;
  border-radius: 6px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .cookieConcentWrapper {
    width: 80vw;
  }
  .cookieConcentWrapper p {
    font-size: 16px;
  }
  .cookieConcentWrapper img {
    width: 130px;
  }
  .cookieConcentWrapper h3 {
    font-size: 28px;
  }
}
