.member-list {
  .post {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #dce5ff;
  }
  .MuiChip-label {
    text-transform: capitalize;
    font-size: 12px;
  }
  .MuiChip-root {
    height: 22px;
    margin-left: 10px;
    &.MuiChip-colorPrimary {
      background-color: #18b760;
    }
    &.MuiChip-colorSecondary {
      background-color: #ff6c72;
    }
  }
  .details {
    flex: 2;
  }
  .price-holder {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .user-desktop-details {
      @media only screen and (max-width: 769px) {
        display: none;
      }
    }
    .btn {
      background-color: #1cb863;
      color: #fff;
    }
  }
  .memberDetails {
    background: #f8faff;
    padding: 30px;
    border: 1px solid #dce5ff;
    border-radius: 0px 0px 15px 15px;
    height: 0;
    transition: 'height 1s ease-in-out';
    &.active {
      height: 100%;
    }
    .actionsection {
      button {
        margin: 5px 15px;
        @media only screen and (max-width: 769px) {
          margin: 5px 0px;
          width: 100%;
        }
      }
    }
  }
}
.MuiButton-containedPrimary {
  color: #fff;
  background-color: #15326d;
}
.failedRecipientModel {
  position: fixed;
  top: 20px;
  left: 10%;
  width: 80%;
  background-color: #f2f2f2;
  margin: 20px auto;
  padding: 15px;
  border-radius: 15px;
  height: 80%;
  z-index: 6000;
  overflow-y: scroll;
}

.MuiTableRow-root.MuiTableRow-footer.total-foot {
  background-color: #f1f1f1;
}
.MuiTableRow-root.MuiTableRow-footer td {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Barlow Condensed', sans-serif !important;
}
