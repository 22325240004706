.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  width: 90%;
  max-width: 500px;
  margin: auto;
  text-align: center;
  &.error-page {
    h2 {
      color: #ff6c72;
    }
  }
  &.success-page {
    h2 {
      color: #18b760;
    }
  }
  & .btn {
    background-color: #18b760;
    color: #fff;
    padding: 5px 15px;
    min-width: 150px;
    border-radius: 20px;
  }
}
a[disabled] {
  pointer-events: none;
}
