.MuiTableRow-footer p {
  margin: 0;
}
.MuiTableCell-body .MuiIconButton-root {
  background-color: #1cb863;
  color: #fff;
  padding: 7px 5px;
  border-radius: 8px;
  margin: 0 0 0 5px;
  width: 38px;
  display: inline-block;
  font-size: 18px;
  text-align: center;
}
.MuiTableCell-body .MuiIconButton-root:hover {
  background-color: orange;
  color: #fff;
}
.MuiTableCell-body .MuiIconButton-root.Mui-disabled {
  background-color: #dedede;
  color: rgba(0, 0, 0, 0.26);
}
