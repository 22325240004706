.installWrapper {
  background: #18b760;
  color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  z-index: 100;
  padding-bottom: 50px;
}

.installWrapper p {
  color: #140665;
}
.installWrapper h3 {
  color: #140665;
}

.buttonWrapper .cancelBtn {
  background-color: #18b760;
  border: none;
  color: #140665;
  text-decoration: underline;
}
.buttonWrapper .installBtn {
  background-color: #f37e0a;
  border: none;
  color: #140665;
  padding: 5px 30px;
  font-weight: 600;
  margin-left: 20px;
}
