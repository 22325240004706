// Variables
$background-color: Thistle;
$width: 350;
$circle-size: 50;

body .scannedTicket {
  width: $width + px;
  height: 100%;
  min-height: 100vh;
  background-color: white;
  margin: 25px auto;
  // position: relative;

  .holes-top {
    height: $circle-size + px;
    width: $circle-size + px;
    background-color: $background-color;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    margin-left: ($circle-size / -2) + px;
    top: ($circle-size / -2) + px;

    &:before,
    &:after {
      content: '';
      height: $circle-size + px;
      width: $circle-size + px;
      background-color: $background-color;
      position: absolute;
      border-radius: 50%;
    }
    &:before {
      left: ($width / -2) + px;
    }
    &:after {
      left: ($width / 2) + px;
    }
  }

  .holes-lower {
    position: relative;
    margin: 25px;
    border: 1px dashed #aaa;
    &:before,
    &:after {
      content: '';
      height: $circle-size + px;
      width: $circle-size + px;
      background-color: $background-color;
      position: absolute;
      border-radius: 50%;
    }

    &:before {
      top: -25px;
      left: ($circle-size / -1) + px;
    }
    &:after {
      top: -25px;
      left: ($width - $circle-size) + px;
    }
  }

  .title {
    padding: 50px 25px 10px;
  }

  .cinema {
    color: #16bd35;
    font-size: 22px;
    margin: 0;
  }

  .movie-title {
    font-size: 40px;
    font-weight: 600;
    color: #000;
    margin-bottom: 0;
  }
  .info {
    padding: 15px 25px;
    margin-top: 0px;
  }
  table {
    width: 100%;
    font-size: 18px;
    margin-bottom: 15px;
    tr {
      margin-bottom: 10px;
    }

    th {
      text-align: left;
      &:nth-of-type(1) {
        width: 25%;
      }
      &:nth-of-type(2) {
        width: 25%;
      }
      &:nth-of-type(3) {
        width: 25%;
      }
    }

    td {
      width: 25%;
      font-size: 32px;
      text-align: left;
    }
  }
  .bigger {
    font-size: 40px;
  }
  .smaller {
    font-size: 28px;
  }
  .personRow {
    border-bottom: 1px solid #000;
  }
  .personRow td {
    padding: 10px 0;
  }
  .serial {
    padding: 25px;
    table {
      border-collapse: collapse;
      margin: 0 auto;
    }
    td {
      width: 3px;
      height: 50px;
    }
  }
  .numbers {
    td {
      font-size: 16px;
      text-align: center;
    }
  }
}
