.ticket-wrapper {
  background: rgb(112, 104, 212);
  background: linear-gradient(
    342deg,
    rgba(112, 104, 212, 1) 35%,
    rgba(160, 98, 167, 1) 100%
  );
  color: #fff;
  width: 100%;
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
}
.ticket-wrapper .ticket-bottom {
  border-top: 2px dashed #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
}
.ticket-wrapper .ticket-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 20px;
  min-height: 180px;
  background: url('../../images/card-bg.png') no-repeat;
  background-size: contain;
  background-position: top right;
}
.ticket-wrapper .ticket-top h2 {
  color: #fff;
}
.ticket-wrapper .ticket-top .btn {
  background-color: #282828;
  font-weight: 600;
  margin-top: 20px;
  padding: 6px 20px;
  color: #fff;
  border-radius: 20px;
}
