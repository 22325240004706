.MuiChip-label {
  text-transform: capitalize;
  font-size: 12px;
}
.MuiChip-root {
  height: 22px;
  margin-left: 10px;
  &.MuiChip-colorPrimary {
    background-color: #18b760;
  }
  &.MuiChip-colorSecondary {
    background-color: #ff6c72;
  }
}

.show_qr_btn {
  background-color: #1cb863;
  color: #fff;
  border: none;
  padding: 3px 10px;
  border-radius: 18px;
  font-size: 16px;
}
